import React, { lazy } from 'react'
import { Navigate } from 'react-router'

import MainTheme from "../Themes/MainTheme"
import FormTheme from "../Themes/FormTheme"

//lazy loading to reduce loading time


const ProductPage = lazy(() => {
    return import("../Views/Products");
});

const LoginForm = lazy(() => {
    return import("../Views/AuthPages");
});

const AddProductPage = lazy(() => {
    return import("../Views/AddProduct");
});

const PageNotFound = lazy(() => {
    return import("../Views/PageNotFound");
});


export const routes = (isLoggedIn = false)=> [
    // A route object has the same properties as a <Route>
    // element. The `children` is just an array of child routes.
    {
        path: '/',
        element: isLoggedIn?<MainTheme showNavBar={true} />:<Navigate to={"/auth/login"} replace />,
        children: [
            { path: '/', element: <ProductPage /> }, 
            { path: '/add-product/:prodId', element: <AddProductPage /> },  
            { path: '*', element: <Navigate to="/404" replace /> }
        ]
    },
    {
        path: 'auth',
        element : <FormTheme />,  
        children: [
            // { path: 'login', element: <LoginForm /> },
            { path: 'login', element: isLoggedIn?<Navigate to="/" replace />:<LoginForm isSignUp={false} /> },
            { path: 'signup', element: isLoggedIn?<Navigate to="/" replace />:<LoginForm isSignUp={true} /> },
            { path: '*', element: <Navigate to="/404" replace /> },
        ]
    },
    {
        path: '404',
        element : <PageNotFound />,
        children: [
            { path: '*', element: <Navigate to="/404" replace /> },
        ]
    },
    {
        path: '404',
        element : <PageNotFound />,
        children: [
            { path: '*', element: <Navigate to="/404" replace /> },
        ]
    },
    { path: '*', element: <Navigate to="404" replace /> },
]