
export const drawerWidth = 240;
// export const API_URL = 'http://127.0.0.1:8000/api/'
export const API_URL = 'https://api.meccastore.co.ke/api/'

export const capitalizeFirstLetter = (string) => (string.charAt(0).toUpperCase() + string.slice(1).toLowerCase())


export const categories = [

    {
        cat: 'Tableware',
        catUrl: 'tableware',
    },
    {
        cat: 'Cookware Kitchenware',
        catUrl: 'cookware-kitchenware',
    },
    {
        cat: 'Cookware',
        catUrl: 'cookware',
    },
    {
        cat: 'Cooking Appliances',
        catUrl: 'cooking-appliances-1',
    },
    {
        cat: 'Home Care',
        catUrl: 'home-care',
    },
]