import { Box } from '@mui/material'
import React from 'react'
import { Outlet } from 'react-router'
import bgImage from '../../Utils/Images/form_bg.jpg'

export default function FormTheme(props) {
  

    return (

      <>

        
        <Box 
          className="w-100 d-flex align-items-center justify-content-center" 
          sx={{
            height: '100vh',
            backgroundImage: {md: 'url('+bgImage+')', xs: ''},
            backgroundColor: {md: '#ccc', xs: '#fff'},
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover'
          }}
        >

          <Box 
            sx={{
              width: {md: '35%', xs: '100%'}
            }}
          >

            <Outlet />
          </Box>
        </Box>
      </>
    )

}