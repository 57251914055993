import { Add, Close, Inventory, Person, ShoppingCart } from '@mui/icons-material';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import MenuIcon from '@mui/icons-material/Menu';
import { Button } from '@mui/material';
import MuiAppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { styled } from '@mui/material/styles';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import axios from 'axios';
import { useSnackbar } from 'notistack';
import * as React from 'react';
import { useNavigate } from 'react-router';
import { SyncLoader } from 'react-spinners';
import useWindowDimensions from '../../Components/WindowDimensions';
import { API_URL, drawerWidth } from '../../Utils/constants';
import logo from '../../Utils/Images/mecca-logo.jpg';


// const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(
//   ({ theme, open }) => ({
//     flexGrow: 1,
//     padding: theme.spacing(3),
//     transition: theme.transitions.create('margin', {
//       easing: theme.transitions.easing.sharp,
//       duration: theme.transitions.duration.leavingScreen,
//     }),
//     marginLeft: `-${drawerWidth}px`,
//     ...(open && {
//       transition: theme.transitions.create('margin', {
//         easing: theme.transitions.easing.easeOut,
//         duration: theme.transitions.duration.enteringScreen,
//       }),
//       marginLeft: 0,
//     }),
//   }),
// );

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  transition: theme.transitions.create(['margin', 'width'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
  background: '#f6f8fa',
  boxShadow: 'none'
}));

// const DrawerHeader = styled('div')(({ theme }) => ({
//   display: 'flex',
//   alignItems: 'center',
//   padding: theme.spacing(0, 1),
//   // necessary for content to be below app bar
//   ...theme.mixins.toolbar,
//   justifyContent: 'flex-end',
// }));

export default function NavBar(props) {
    // const { OutletPage } = props
    const user = localStorage.getItem("user")?JSON.parse(localStorage.getItem("user")):null
    const { height, width } = useWindowDimensions();
    const [open, setOpen] = React.useState(width > 500);
    const [isLogoutLoading, setIsLogoutLoading] = React.useState(false)
    const navigate = useNavigate()
    const { enqueueSnackbar, closeSnackbar } = useSnackbar()

    console.log("width", height)

    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };

    
    const handleOpenUrl = (url) => {
        window.location.href = (url);
    };

    
    const handleLogout = () => {
        if(user) {
            setIsLogoutLoading(true)
            const headers = {
                "Content-Type": "application/json",
                Authorization: `Bearer ${user.accessToken}`
            }
            
            // axios.defaults.headers.common['Authorization'] = user.accessToken;
            axios.post(API_URL + 'auth/logout', {}, {headers:headers})
                .then(()=>{
    
                    setIsLogoutLoading(false)
                    localStorage.removeItem("user")
                    navigate("/auth/login")
                })
                .catch((err)=>{
                    setIsLogoutLoading(false)
                    console.log(err)
                    showSnackBar("Could not logout, try again later", "error")
                })
        }else {
            localStorage.removeItem("user")
            navigate("/auth/login")
        }
        
    }

    function showSnackBar(msg, variant = 'success'){
        enqueueSnackbar(msg, {
            variant: variant,            
            action: (key) => (
                <IconButton style={{color: '#000'}} size="small" onClick={() => closeSnackbar(key)}>
                    <Close />
                </IconButton>
            ),
    })}

    React.useEffect(()=>{
        setOpen(width > 500)
    }, [width])

    return (
        <Box sx={{ display: 'flex' }}>
            <CssBaseline />
            <AppBar position="fixed" open={open}>
                <Toolbar 
                    sx={{
                        // boxShadow: '2px 2px 4px #d3d3d3',
                        boxShadow: 'none',
                        background: '#fff',
                        p: 0,
                        mx: {md: 3, xs: 0},
                        my: {md: 1, xs: 0},
                        borderRadius: {md: '8px', xs: 0}
                    }}
                >
                    {
                        width < 500?
                            <IconButton
                                color="inherit"
                                aria-label="open drawer"
                                onClick={open?handleDrawerClose:handleDrawerOpen}
                                edge="start"
                                sx={{ mx: 2, color: '#000' }}
                            >
                                {
                                    open?"":<MenuIcon />
                                }
                            </IconButton>

                        :""
                    }
                    <Typography variant="h6" noWrap component="div" sx={{color: '#000'}}>
                        Products
                    </Typography>
                    <div style={{flex: 1}}></div>
                    <Button
                        size="small"
                        variant="outlined"
                        className="rounded-pill"
                        startIcon={<Add />}
                        sx={{textTransform: 'none', mr: 1}}
                        onClick={()=>window.location.href = ('/add-product/new')}
                    >
                        Create
                    </Button>
                    <IconButton
                        onClick={handleLogout}
                    >
                        {
                            
                            isLogoutLoading?
                                <SyncLoader
                                    size={8}
                                    color={"#000"}
                                    loading={isLogoutLoading}
                                />
                            :
                                <Person />
                        }
                    </IconButton>
                </Toolbar>
                
            </AppBar>
            <Drawer
                sx={{
                width: drawerWidth,
                flexShrink: 0,
                '& .MuiDrawer-paper': {
                    width: drawerWidth,
                    boxSizing: 'border-box',
                },
                }}
                variant={width > 500?"persistent":"temporary"}
                anchor="left"
                open={open}
            >
                <Box className="d-flex">
                    <div style={{flex: 1}}></div>
                    {
                        width < 500?
                            <IconButton
                                color="inherit"
                                aria-label="open drawer"
                                onClick={open?handleDrawerClose:handleDrawerOpen}
                                sx={{ m: 1, color: '#000' }}
                            >
                                {
                                    open?<ChevronLeftIcon />:<MenuIcon />
                                }
                            </IconButton>

                        :""
                    }
                </Box>
                <Box className="d-flex justify-content-center align-items-center" sx={{px: 2, py: 1}}>


                    <img
                        src={logo}
                        style={{
                            width: '100px',
                            height: '100px'
                        }}
                        alt="Mecca store logo"
                    />
                </Box>
                <List>
                    {['Inventory', 'Shop'].map((text, index) => (
                        <ListItem key={text} disablePadding onClick={()=>handleOpenUrl(index % 2 === 0 ? "/":"https://meccastore.co.ke/")}>
                            <ListItemButton>
                                <ListItemIcon
                                    sx={{
                                        minWidth: 'auto',
                                        mr: 2
                                    }}
                                >
                                {index % 2 === 0 ? <ShoppingCart color="lightPurple" /> : <Inventory color="lightPurple" />}
                                </ListItemIcon>
                                <ListItemText primary={<span style={{color: '#000', fontSize: '14px'}}>{text}</span>} />
                            </ListItemButton>
                        </ListItem>
                    ))}
                </List>
                <Divider />
            </Drawer>
            
            {/* <Main open={open}>
                <DrawerHeader />
                {OutletPage}
            </Main> */}
        </Box>
    );
}
