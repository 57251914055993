import { Box } from '@mui/material'
import React from 'react'
import { Outlet } from 'react-router'
import NavBar from '../../Components/NavBar'
import { drawerWidth } from '../../Utils/constants'

export default function MainTheme(props) {

    

    return (

      <Box>

        <NavBar 
          // OutletPage={
          //   <Outlet />
          // }
        />
        <Box
          sx={{
            marginTop: {md: '80px', xs: '65px'},
            marginLeft: {md: (drawerWidth + 30) + 'px' , xs: '8px'},
            marginRight: {md: 30 + 'px' , xs: '8px'},
            pt: 0.5,
            pb: 3
          }}
        >
          <Outlet />
        </Box>
      </Box>
    )

}