import React, { Suspense } from 'react'
import './App.css';
import { useRoutes } from 'react-router';
import { routes } from './Routes/route';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { SnackbarProvider } from 'notistack';
import { Avatar, Box } from '@mui/material';
import { SyncLoader } from 'react-spinners';
import logo_loader from './Utils/Images/mecca-logo.jpg'

const theme = createTheme({
  palette: {
    primary: {
      main: '#3f8cd9'
    },
    secondary: {
      main: '#000'
    },
    danger: {
      main: '#ff6666'
    },
    lightPurple: {
      main: '#bec3dd'
    }
  }
})
function App() {

  const user = localStorage.getItem("user")?JSON.parse(localStorage.getItem("user")):null
  const element = useRoutes(routes(user && user.expires_on && new Date(parseFloat(user.expires_on)) > new Date()))

  return (
    <ThemeProvider theme={theme}> 
      <SnackbarProvider maxSnack={1}>
        <Suspense
          fallback={
            <Box className="d-flex justify-content-center align-items-center w-100" sx={{height: '100vh'}} >
              <div>
                <Avatar variant="square" src={logo_loader} sx={{width: '120px', height: '120px'}} />
                <SyncLoader loading={true} size={7} color="#fff" />
              </div>
            </Box>
          }
        >
          {element}
        </Suspense>
      </SnackbarProvider>
    </ThemeProvider>
  );
}

export default App;
